<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить клуб</h1>
      </div>
    </div>
    <ClubAddForm ref="clubAddForm" />
    <form class="form">
      <div class="btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.CLUB_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ClubAddForm from "@component/Form/ClubAddForm";

import GetGoogleMap from "@library/GetGoogleMap";

export default {
  name: "ClubAdd",
  components: {
    ClubAddForm,
  },
  data() {
    return {
    
    };
  },
  methods: {
    
    async save() {
      
      let formData = this.$refs.clubAddForm.formSubmitGetData();
      if(!formData) { return; }
      
      let sendData = Object.assign({}, formData);
      
      sendData.phone = sendData.phone.replace(/-|\s+/g,"");
      //
      const contact_set = [];

      if(sendData.contactTelegram  ) { contact_set.push({ type: "TGRM", value: sendData.contactTelegram  }); }
      if(sendData.contactEmail     ) { contact_set.push({ type: "MAIL", value: sendData.contactEmail     }); }
      if(sendData.contactTwitter   ) { contact_set.push({ type: "TWTR", value: sendData.contactTwitter   }); }
      if(sendData.contactWhatsapp  ) { contact_set.push({ type: "WTSP", value: sendData.contactWhatsapp  }); }
      if(sendData.contactInstagram ) { contact_set.push({ type: "INST", value: sendData.contactInstagram }); }
      
      delete sendData.contactTelegram;
      delete sendData.contactEmail;
      delete sendData.contactTwitter;
      delete sendData.contactWhatsapp;
      delete sendData.contactInstagram;


      try {
        const gObj = await GetGoogleMap();
        const coord = await gObj.getAddressCoordinate(sendData.address.value);
        //
        sendData.address.data.geo_lat = coord.lat;
        sendData.address.data.geo_lon = coord.lon;
      } catch (e) {
        this.$dialogs.alert('Не удалось определить адресс');
      }
      
      // location set
      sendData.location = JSON.stringify({
        latitude: sendData.address.data.geo_lat,
        longitude: sendData.address.data.geo_lon
      });
      sendData.address = sendData.address.value;
      
      // feature set
      const feature_set = sendData.feature_set.map(f => ({ comfort: f.comfort }));
      delete sendData.feature_set;

      try {
        const res = await RequestManager.Club.addClub(sendData);
        const contact_set_string = JSON.stringify(contact_set);
        const feature_set_string = JSON.stringify(feature_set);
        // патчим контакты и/или удобства
        const res2 = await RequestManager.Club.updateClub({ clubId: res.id, club: { 
            contact_set: contact_set_string,
            feature_set: feature_set_string,
          } 
        });
        this.$toasts.push({
          message: 'Клуб успешно добавлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_LIST });
      } catch (e) {
        console.error(e);
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
